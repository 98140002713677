<template>
  <div class="desktopLayoutClass">
    <v-app>

          <!-- Dialog Start -->
    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Unauthorized!
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogUnauthorized" style="background-color: #1467BF;">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Something went wrong...
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- Dialog Close -->

    <div
      v-show="show_header"
      style="
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-left: 5px;
      margin: -1px;

      "
        class="header"
      >
      <div style="display: flex; flex-direction: row; width: 100%" >
        <div
          style="width: 10%; padding-top: 6px"
          align="left"
          v-on:click="redirectToHomePage()"
          >
          <img
          src="https://img.icons8.com/material-outlined/20/000000/left.png"
          />
        </div>
        <div style="width: 80%" align="left">
        <span style="font-weight: 600; font-size: 15pt"
        >Information</span
        >
        </div>
      </div>
       <div
            v-if="!isLogedIn"
          style="width: 10%; padding-top: 2px; font-weight:700; color:#1467BF"
          align="left"
          v-on:click="redirectToLoginPage()"
          >
            login
        </div>
    </div>


  <v-col cols="12" >
<div style="display:flex; flex-direction:column">
        <div>
          <img class="bannerClass" style="width:95%" src='https://s3iconimages.mymedicine.com.mm/kbzCareBanner2.png' >
        </div>
         <!-- <div v-if="$store.state.locale =='mm'" >
             <img class="bannerClass" style="width:95%" src='https://s3iconimages.mymedicine.com.mm/BlueBanner_mm.png' >
         </div>
          <div v-if="$store.state.locale =='en'" >
             <img class="bannerClass" style="width:95%" src='https://s3iconimages.mymedicine.com.mm/BlueBanner_en.png' >
         </div> -->
            <!-- <div v-if="$store.state.locale =='en'" style="margin-top:5%;">
                <h3>
                  <span>
                    {{ $t("Customer.KbzCareStaticContent.First_Para_Exclusively_Brought_To_You_By_MyMedicine") }} <br>
                  <span style="color:#3492F0"> {{ $t("Customer.KbzCareStaticContent.First_Para_Mymedicine") }}</span>
                  </span>

                </h3>
            </div>
             <div v-else style="margin-top:5%;">
                <h3>
                   <span style="color:#3492F0"> {{ $t("Customer.KbzCareStaticContent.First_Para_Mymedicine") }} <span style="color:black">
                     {{$t("Customer.KbzCareStaticContent.First_Para_Exclusively_Brought_To_You_By_MyMedicine")}}
                     </span>  <br> <span style="color:black"> {{ $t("Customer.KbzCareStaticContent.First_Para_KBZemployeesandtheirfamilies") }}</span> </span>
                </h3>
            </div> -->
                <!-- <div style="display:flex; flex-direction:row;justify-content: space-around; margin-top:5%;" v-if="$store.state.locale =='en'" >

                <div class="pa-2">
                  <img width="100%" height="100%"  src='https://s3iconimages.mymedicine.com.mm/blueBoxpromo.png' >
                </div>
                 <div class="pa-2">
                  <img width="100%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/pinkBocpromo.png' >
                </div>
            </div>

             <div style="display:flex; flex-direction:row;justify-content: space-around; margin-top:10%;" v-if="$store.state.locale =='mm'" >
                <div class="pa-2">
                  <img width="100%" height="100%"  src='https://s3iconimages.mymedicine.com.mm/blueBoxpromo_mm.png' >
                </div>
                 <div class="pa-2">
                  <img width="100%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/pinkBoxpromo_mm.png' >
                </div>
            </div> -->

          <div class="promoTailes">
            <div>
              <img width="90%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/kbzcareBlueBox2.png' >
            </div>
            <div>
              <img width="90%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/kbzcareRedBox2.png' >
            </div>
            <div>
              <img width="90%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/kbzcareWhiteBox2.png' >
            </div>
          </div>
          <div style="margin-top:18px">
            <h4 style="font-size:#111;">
               <span style="color:#3492F0"> {{ $t("Customer.KbzCareStaticContent.First_Para_Mymedicine") }} </span> {{ $t("Customer.KbzCareStaticContent.First_Para_Version2") }}
            </h4>
          </div>
          <div style="margin-top:18px">
            <img width="90%"  height="100%" src='https://s3iconimages.mymedicine.com.mm/kbzcareBlueButton2.png' >
          </div>
          <!-- <div style="margin-top:5%">
            <h4 style="font-size:#111;">
                {{ $t("Customer.KbzCareStaticContent.Second_Para") }}
            </h4>
        <div v-if="$store.state.locale =='en'">
           <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/specialization_en.jpeg' >
        </div>
         <div v-if="$store.state.locale =='mm'">
           <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/specialization_mm.png' >
        </div>
        </div> -->
        <!-- <div v-if="$store.state.locale =='en'" style="padding:10px">  <h4 style="color:#1A1347"> {{ $t("Customer.KbzCareStaticContent.Third_Para_Getyour") }} </h4>
          <h2 style="color:#1A1347">  {{ $t("Customer.KbzCareStaticContent.Third_Para_1timefreeconsultation") }} </h2> <h4 style="color:#1A1347"> {{ $t("Customer.KbzCareStaticContent.Third_Para_withourprofessorsandspecialists") }}</h4>
        </div> -->
         <!-- <div v-else style="padding:10px">  <h4 style="color:#1A1347"> {{ $t("Customer.KbzCareStaticContent.Third_Para_Getyour") }} </h4>
        </div> -->

        <!-- <div v-if="$store.state.locale =='en'" >
          <div style="display:flex; flex-direction:row;justify-content: space-evenly;">
            <div style="width:50%" >
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/KhinMaungWin_en.png' >
            </div>
            </div>
            <div style="margin-left:5px; width:50%;">
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/WinWinMyint_en.png' >
            </div>
            </div>
           </div>
          </div>
          <div v-else>
              <div style="display:flex; flex-direction:row;justify-content: space-evenly;">
            <div style="width:50%" >
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/KhinMaungWin_mm.png' >
            </div>
            </div>
            <div style="margin-left:5px; width:50%;">
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/WinWinMyint_mm.png' >
            </div>
            </div>
           </div>
          </div> -->
          <!-- <div v-if="$store.state.locale =='en'" style="margin-top:10px;">
          <div style="display:flex; flex-direction:row;justify-content: space-evenly;">
          <div style="width:50%">
          <div @click="redirectToBookingPage()">
              <img  style="width:100%; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/AyeAyeThein_en.png' >
          </div>
            </div>
            <div style="margin-left:5px; width:50%">
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; border-radius:8px; height: 252.97px;" src='https://s3iconimages.mymedicine.com.mm/WinWaiPhyoPaing_en.jpeg' >
            </div>
            </div>
          </div>
        </div> -->
        <!-- <div v-else>
           <div style="display:flex; flex-direction:row;justify-content: space-evenly;">
          <div style="width:50%">
          <div @click="redirectToBookingPage()">
              <img  style="width:100%; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/AyeAyeThein_mm.png' >
          </div>

            </div>
            <div style="margin-left:5px; width:50%">
            <div @click="redirectToBookingPage()">
              <img  style="width:100%; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/WinWaiPhyoPaing_mm.png' >
            </div>
            </div>
        </div>
      </div> -->
      <div style="margin-top:18px">
        <h4>
        <span style="color:red">{{ $t("Customer.KbzCareStaticContent.Bottom_Para_KBZ") }}</span>  {{ $t("Customer.KbzCareStaticContent.Bottom_Para_version2") }}
        </h4>
      </div>
</div>
</v-col>
</v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";

export default {
  name: "KbzCaresInfo",
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      pageName: "DoctorConsultationInfo",
      isMobile:false,
      isLogedIn:false,
      show_header: true,
    };
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == "en")
      document.title = "KBZ Care Information"
    else
      document.title = "KBZ Care အစီအစဥ်၏ အချက်အလက်များ"
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
     this.isLogedIn = true;
    // if(!this.currentCustomer){
    //   this.$router.push({
    //     name : "Login"
    //   })
    // }
    // else{

    //    }
  },
  components: {
    NavBar,
    bottomnavbar,
  },
  methods: {
    redirectToBookingPage(){
            this.$router.push({
        name: "BookAppointment",
      });
      },
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    redirectToLoginPage(){
         this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },

    redirectToHomePage() {
      this.$router.go(-1);
    },

  }
};
</script>

<style>
/* @media (min-width: 800px){
  .infoClass{
   width: 750px !important;
   margin-left: auto !important;
   margin-right: auto !important;

  }
  .bannerClass{
    width: 95% !important;
    height: auto;
  }
  .PromoCardClass{
    margin-left: 10px !important;
    width: 175px !important;
  }
    .PromoCardClass2{
    margin-left: 10px !important;
    width: 175px !important;
  }
  .BtnClass{
    margin-bottom: 10% !important;
  }
    .BtnClass1{
    margin: 10% !important;
  }
  .contentClass{
    margin-bottom: 0%;
  }
  } */
  .promoTailes{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 18px;
  }
</style>